export const DEFAULT_JSON = {
  name: "John Doe",
  age: 35,
  graduated: true,
  occupation: "Software Engineer",
  skills: [
    { language: "JavaScript", yoe: 8 },
    { language: "Java", yoe: 5 }
  ],
  certifications: [
    "Certificate A",
    "Certificate B"
  ],
  patents: null
};
